/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-11 01:50:20
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-04-06 12:17:17
 */

import http from "./axios_init";

export default {
  /**
   * 获取 enum 的参数
   * @returns
   */
  get_enums() {
    return http.get("/common/enums");
  },

  /**
   * 公开的系统参数
   * @returns
   */
  get_config() {
    return http.get("/common/config");
  },
  /**
   * 城市区所有数据列表
   */
  get_area() {
    return http.get("seller/v1/common/area");
  },
  /**
   * 城市区域树形结构数据
   */
  get_area_tree() {
    return http.get("seller/v1/common/tree-area");
  },

  /**
   * 获取房屋类型tree数据
   * @returns
   */
  get_building_tree() {
    return http.get("seller/v1/common/tree-building");
  },

  /**
   * 下拉搜索房东
   * @param {*} q
   * @returns
   */
  get_landlord(q) {
    return http.get(`seller/v1/common/select-landlord?q=${q ?? ""}`);
  },

    /**
   * 下拉搜索房源库存
   * @param {*} q
   * @returns
   */
     get_housestore(q) {
      return http.get(`seller/v1/common/select-housestore?q=${q ?? ""}`);
    },

  /**
   * 获取全部的床型
   */
  get_beds() {
    return http.get("seller/v1/common/beds");
  },

  /**
   * 获取房屋设施tree结构数据
   */
  get_facility() {
    return http.get("seller/v1/common/tree-facility");
  },

  /**
   * 获取退订规则
   * @returns
   */
  get_unsubscribe() {
    return http.get("seller/v1/common/unsubscribe");
  },

  /**
   * 获取所有的门店数据
   * @returns
   */
  get_stops() {
    return http.get("seller/v1/common/stops");
  },

  /**
   * 获取所有的入住要求数据
   * @returns
   */
  get_askfors() {
    return http.get("seller/v1/common/askfors");
  },

  /**
   * 关键字搜索楼盘下拉列表 默认20条数
   * @returns
   */
  get_houses(q) {
    return http.get("seller/v1/common/select-house?q=" + q ?? "");
  },

  /**
   * 获取文件上传地址
   * @param {*} ext
   * @returns
   */
  get_upload_url(ext) {
    return http.post("seller/v1/common/create-uploadurl", {
      bucket: "files",
      ext: ext,
    });
  },

  /**
   * 未来两年内的节假日
   * @returns
   */
  get_holiday() {
    return http.get("seller/v1/common/holiday");
  },

    /**
   * 获取会员等级
   * @returns
   */
     get_grade() {
      return http.get("seller/v1/common/grade");
    },

    /**
   * 获取分成比例规则
   * @returns
   */
     get_incomerules() {
      return http.get("seller/v1/common/incomerules");
    },

    /**
   * 获取设置的所有消费项
   * @returns
   */
     get_consumptions() {
      return http.get("seller/v1/common/consumptions");
    },


        /**
   * 数据权限数据
   * @returns
   */
        get_permission_data() {
          return http.get("seller/v1/common/data_prm");
        },
    
};
