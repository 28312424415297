<!--
 * @Description: 入住要求
 * @Author: 琢磨先生
 * @Date: 2022-05-27 15:15:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-18 04:39:09
-->

<template>
  <el-scrollbar>
    <el-form :model="form" ref="form" :rules="rules" label-position="top">
      <div class="askfor-box">
        <div class="row" v-for="item in askforList" :key="item.id">
          <div class="top-radio">
            <span class="name">{{ item.name }}</span>
            <el-radio-group v-model="item.check_result">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </div>
          <div v-if="item.check_result && item.is_allow_remark">
            <el-input v-model="item.remark" type="textarea" :rows="5"  :placeholder="item.remark_hint"></el-input>
          </div>
        </div>
      </div>

      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </el-scrollbar>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      loading: false,
      saving: false,
      form: {},
      rules: {
        // name: [
        //   {
        //     required: true,
        //     message: "标题必填",
        //     trigger: "blur",
        //   },
        // ],
      },
      askforList: [],
    };
  },
  emits: ["change"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          if (this.item.id) {
            this.form = Object.assign({}, this.item);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_askfors().then((res) => {
      if (res.code == 0) {
        this.askforList = res.data;
        this.askforList.forEach((item) => {
          if (this.form.askfors) {
            var x = this.form.askfors.find((o) => o.askfor_id == item.id);
            if (x) {
              item.check_result = x.check_result;
              item.remark = x.remark;
            }
          } else {
            item.check_result = false;
          }
        });
      }
    });
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {

          var array = [];

          this.askforList.forEach((item) => {
            array.push({
              askfor_id: item.id,
              askfor_name: item.name,
              remark: item.remark ?? "",
              check_result: item.check_result,
              house_id: this.form.id,
            });
          });
          this.form.askfors = array;

          this.saving = true;
          this.$http
            .post("seller/v1/house/edit/askfor", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("change", "askfor");
              }
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style   scoped>
.askfor-box {
  margin-bottom: 20px;
}
.row {
  width: 400px;
  padding: 10px 0;
  color: #666;
  border-bottom: 1px solid var(--el-border-color-lighter);
}
.top-radio{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}


.row .name {
  font-size: 14px;
}
</style>